import Loadable from "react-loadable"
import LoadingPage from "views/LoadingPage/LoadingPage.jsx"

export const HomePage = Loadable({
    loader: () => import("views/HomePage/HomePage.jsx"),
    loading: LoadingPage
});

export const LoginPage = Loadable({
    loader: () => import("views/LoginPage/LoginPage.jsx"),
    loading: LoadingPage
});

export const LogoffPage = Loadable({
    loader: () => import("views/LogoffPage/LogoffPage.jsx"),
    loading: LoadingPage
});

export const SignUpPage = Loadable({
    loader: () => import("views/SignUpPage/SignUpPage.jsx"),
    loading: LoadingPage
});

export const BackupEmailPage = Loadable({
    loader: () => import("views/BackupEmailPage/BackupEmailPage.jsx"),
    loading: LoadingPage
});

export const ErrorPage = Loadable({
    loader: () => import("views/ErrorPage/ErrorPage.jsx"),
    loading: LoadingPage
});

export const PrequalPage = Loadable({
    loader: () => import("views/PrequalPages/PrequalPage.jsx"),
    loading: LoadingPage
});

export const PostPrequalPage = Loadable({
    loader: () => import("views/PostPrequalPage/PostPrequalPage.jsx"),
    loading: LoadingPage
});

export const UndergradAppPage = Loadable({
    loader: () => import("views/ApplicationPages/UndergradApp/UndergradAppPage.jsx"),
    loading: LoadingPage
});

export const ReFiAppPage = Loadable({
    loader: () => import("views/ApplicationPages/RefinanceApp/ReFiAppPage.jsx"),
    loading: LoadingPage
});

export const PayoffVerificationPage = Loadable({
    loader: () => import("views/PayoffVerificationPage/PayoffVerPage.jsx"),
    loading: LoadingPage
});

export const UndergradReApplyPage = Loadable({
    loader: () => import("views/ApplicationPages/UndergradReApp/UndergradReApplyPage.jsx"),
    loading: LoadingPage
});

export const PostAppPage = Loadable({
    loader: () => import("views/PostAppPage/PostAppPage.jsx"),
    loading: LoadingPage
});

export const LoginWithTokenPage = Loadable({
    loader: () => import("views/LoginWithTokenPage/LoginWithTokenPage.jsx"),
    loading: LoadingPage
});

export const PortalPage = Loadable({
    loader: () => import("views/PortalPage/PortalPage.jsx"),
    loading: LoadingPage
});

export const UnAuthPortalPage = Loadable({
    loader: () => import("views/PortalPage/UnAuthPortalPage.jsx"),
    loading: LoadingPage
});

export const RoutingEngine = Loadable({
    loader: () => import("router/RoutingEngine.jsx"),
    loading: LoadingPage
});

export const CareerWorkshopRegistrationPage = Loadable({
    loader: () => import("views/ResourcePages/CareerWorkshopRegistrationPage.jsx"),
    loading: LoadingPage
});

export const PostSignaturePage = Loadable({
    loader: () => import("views/PostSignaturePage/PostSignaturePage.jsx"),
    loading: LoadingPage
});