import { getOpportunityStages } from './endpoints'

const sessionStorageKey = 'OpportunityStages'

// last updated - 2 DEC 2020
// APPLICATION: {SFDC_Name: "Application", Probability: 20, Type: "Open", StageName: "APPLICATION", ForecastCategory: "Pipeline"}
// CLOSED_CANCELLED: {SFDC_Name: "Closed (Cancelled)", Probability: 0, Type: "Closed/Lost", StageName: "CLOSED_CANCELLED", ForecastCategory: "Omitted"}
// CLOSED_DECLINED: {SFDC_Name: "Closed (Declined)", Probability: 0, Type: "Closed/Lost", StageName: "CLOSED_DECLINED", ForecastCategory: "Closed"}
// CLOSED_FUNDED: {SFDC_Name: "Closed (Funded)", Probability: 100, Type: "Closed/Won", StageName: "CLOSED_FUNDED", ForecastCategory: "Omitted"}
// CLOSED_EXPIRED: {SFDC_Name: "Closed (EXPIRED)", Probability: 0, Type: "Closed/Lost", StageName: "CLOSED_EXPIRED", ForecastCategory: "Omitted"}
// DISBURSEMENT: {SFDC_Name: "Disbursement", Probability: 100, Type: "Open", StageName: "DISBURSEMENT", ForecastCategory: "Pipeline"}
// FINAL_UNDERWRITING: {SFDC_Name: "Final Underwriting", Probability: 30, Type: "Open", StageName: "FINAL_UNDERWRITING", ForecastCategory: "Pipeline"}
// MANUAL_REVIEW: {SFDC_Name: "Manual Review", Probability: 70, Type: "Open", StageName: "MANUAL_REVIEW", ForecastCategory: "Pipeline"}
// PREP_DOCUMENTS: {SFDC_Name: "Prep Documents", Probability: 70, Type: "Open", StageName: "PREP_DOCUMENTS", ForecastCategory: "Pipeline"}
// PRE_QUALIFICATION: {SFDC_Name: "Pre-Qualification", Probability: 10, Type: "Open", StageName: "PRE_QUALIFICATION", ForecastCategory: "Pipeline"}
// PULL_CREDIT: {SFDC_Name: "Pull Credit", Probability: 60, Type: "Open", StageName: "PULL_CREDIT", ForecastCategory: "Pipeline"}
// RIGHT_TO_CANCEL_PERIOD: {SFDC_Name: "Right to Cancel Period", Probability: 90, Type: "Open", StageName: "RIGHT_TO_CANCEL_PERIOD", ForecastCategory: "Commit"}
// SCHOOL_CERTIFICATION: {SFDC_Name: "School Certification", Probability: 90, Type: "Open", StageName: "SCHOOL_CERTIFICATION", ForecastCategory: "Pipeline"}
// SEND_DOCUMENTS: {SFDC_Name: "Send Documents", Probability: 70, Type: "Open", StageName: "SEND_DOCUMENTS", ForecastCategory: "Pipeline"}
// UNQUALIFIED: {SFDC_Name: "Unqualified", Probability: 0, Type: "Closed/Lost", StageName: "UNQUALIFIED", ForecastCategory: "Omitted"}
// WAITING_FOR_SIGNED_DOCS: {SFDC_Name: "Waiting for Signed Docs", Probability: 80, Type: "Open", StageName: "WAITING_FOR_SIGNED_DOCS", ForecastCategory: "Best Case"}
// PREP_FINAL_DISCLOSURE: {SFDC_Name: "Prep Final Disclosure", Probability: 90, Type: "Open", StageName: "PREP_FINAL_DISCLOSURE", ForecastCategory: "Prep Final Disclosure"}

async function fetchOpportunityStages() {
    let response = await fetch(getOpportunityStages)
    let data = await response.json()
    let items = await JSON.parse(data.body).Items
    const opp_stages = items.reduce(
        (obj, item) => Object.assign(obj, { [item.StageName]: item }), {});
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(opp_stages))
    // .then(response => response.json())
    // .then(json => JSON.parse(json.body).Items)
    // .then(items => {
    //     const opp_stages = items.reduce(
    //         (obj, item) => Object.assign(obj, { [item.StageName]: item }), {});
    //     sessionStorage.setItem(sessionStorageKey, JSON.stringify(opp_stages))
    // })
}

function readOpportunityStages() {
    const opp_stages = sessionStorage.getItem(sessionStorageKey)
    return JSON.parse(opp_stages)
}

export default {
    fetchOpportunityStages,
    readOpportunityStages
}