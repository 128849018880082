import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router";
import MetaTags from 'react-meta-tags';
import * as serviceWorker from './serviceWorker';

import App from './App';
import CssBaseline from '@material-ui/core/CssBaseline';
import Amplify /*, { Auth }*/ from 'aws-amplify';
import awsconfig from './aws-exports';
import { ApolloProvider } from 'react-apollo'; // To connect Apollo Client to React
import {guest_client} from 'common/AppSyncClient.js';
import {Auth_config, /*oauth_config*/} from 'common/cognito/cognitoConfig';

// retrieve temporary AWS credentials and sign requests
Amplify.configure(awsconfig); // need to define User Pool in config seperately
Amplify.configure({
    Auth: Auth_config,
    Storage: {
      AWSS3: {
        bucket: 'am-applicant-docs',
        region: 'us-east-1'
      }
    }
});
// Auth.configure({oauth: oauth_config}); // disabled federated logins

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <ApolloProvider client={guest_client} className="App">
      <MetaTags>
        <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          <meta 
            name="description" 
            content="Affordable loans without a cosigner. Chicago Student Loans by A.M. Money. Apply Now."
          />
          <meta httpEquiv="cache-control" content="max-age=0" />
          <meta httpEquiv="cache-control" content="no-cache" />
          <meta httpEquiv="expires" content="0" />
          <meta httpEquiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
          <meta httpEquiv="pragma" content="no-cache" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
      </MetaTags>
      <CssBaseline />
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
