import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
// routes 
import routes from 'router/routes.js';
// authentication resources
import { Auth, Hub } from 'aws-amplify';
// import {cognitoIDP} from 'common/cognito/endpoints'
// import jwtDecode from 'jwt-decode'

export const Context = React.createContext();

class ContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
          authState: 'loading',
          authData: null,
          authError: null,
          user: null,
          notifications: []
        }
        const {history} = this.props

        // let the Hub module listen on Auth events
        Hub.listen('auth', async (data) => {
            const {payload} = data
            switch (payload.event) {
                case 'signIn':
                    const user = 'attributes' in Auth.user ? Auth.user.attributes 
                        : Auth.user.challengeParam.userAttributes // for users that go through "set-password" route
                    this.setState({ 
                        authState: 'signedIn', 
                        authData: payload.data,
                        authError: null,
                        user
                    });
                    this.reroute()
                    break;
                case 'signIn_failure':
                    this.setState({
                        authState   : 'signIn',
                        authData    : null,
                        authError   : payload.data,
                        user        : null,
                    });
                    break;
                case 'signOut':
                    this.setState({
                        authState   : 'signIn',
                        authData    : payload.data,
                        authError   : null,
                        user        : null,
                    });
                    history.push(routes.start())
                    break;
                case 'signUp':
                    this.setState({
                        authState   : 'signIn',
                        authData    : payload.data,
                        authError   : null,
                        user        : null,
                    });
                    break;
                default:
                    break;
            }
        });
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser().then(userData => {
            this.setState({ authState: 'signedIn', user: Auth.user.attributes });
        }).catch(e => {
            this.setState({ authState: 'signIn', user: null });       
        }); 
    }

    reroute = () => {
        const {history} = this.props
        const { from } = this.props.location.state || { from: { pathname: routes.magicRoute() } }
        history.push(from.pathname)
    }

    updateNotifications = notifications => this.setState({ notifications })

    // depricated in new aws-amplify version
    // getUserData = async () => {
    //     try {
    //         const credentials = await Auth.currentAuthenticatedUser()
    //         const jwt = credentials.params.Logins[cognitoIDP]
    //         const user = jwtDecode(jwt)
    //         return user
    //     }
    //     catch (error) {
    //         // logged in with temp password
    //         console.log('error signing in', error)
    //     }
    // }

    render() {
        return (
            <Context.Provider 
                value={{
                    updateNotifications: this.updateNotifications,
                    ...this.state
                }}
            >
                {this.props.children}
            </Context.Provider>
        )
    }
}

export default withRouter(ContextProvider);
