export default {
    aws_cognito_domain: 'ammoney',
    client_id: window.location.host.includes('localhost')
      ? '3ujf1758mpuk468r094ib6kdrv'
      : '1d8n79slkpvebaf1brdlipotc3',
    response_type: 'code', // or 'token',
    identityPoolId: 'us-east-1:d558a831-fe40-48e3-afd7-10a107f565d2',
    userPoolId: 'us-east-1_bGArhCkRs',
    login_redirect_uri: `${window.location.protocol}//${window.location.host}`,
    signout_redirect_uri: `${window.location.protocol}//${window.location.host}`,
    authorization_code: ''
  }