import React, { Component } from "react";
// routes
import { Route, Switch } from "react-router-dom";
import routes from "router/routes.js";
import PrivateRoute from "router/PrivateRoute";
// pages 
import LoadingPage from "views/LoadingPage/LoadingPage.jsx";
//back-end resources
// import { withApollo } from "react-apollo";
// import {guest_client} from "common/AppSyncClient.js"
// import gql from "graphql-tag";
// import {getSchoolNames} from "graphql/queries";
// React Context Wrapper
import ContextProvider from "components/ContextProvider/ContextProvider";
// Analytics
import { initGA, logPageView } from "common/analytics/GoogleAnalytics";
// import LogRocket from 'logrocket';
// Public appsync API key (for guest users)
import API_key from "common/api/appSyncAPIKey";
// SFDC opportunity stages (read from DynamoDB)
import opportunityStages from "common/api/opportunityStages";
// pages for this product
import * as views from "./views";
// global styles
import "assets/scss/material-kit-pro-react.scss?v=1.7.0";
// js libraries/apis
// import ldinsp from "common/inspectlet.js";
// import intercom from "common/intercom.js";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    }
  }

  async componentDidMount() {
    // Google analytis - runs synchronously 
    initGA()
    logPageView()

    // LogRocket
    // LogRocket.init('knojtz/am-money');

    // Inspectlet
    window.__insp = window.__insp || []
    window.__insp.push(["wid", 1646906913])
    const ldinsp = this.ldinsp()
    setTimeout(ldinsp, 0)

    // Intercom
    this.intercom()
    window.intercomSettings = {
      app_id: "vsqeg8oe"
    }

    // load constants
    await Promise.all([
      API_key.fetchAPIKey(), // this needs to be the first thing to run
      opportunityStages.fetchOpportunityStages()
    ])

    // Amanda having problems with dropdowns coming from Dynamo. Need to investigate. 
    // TODO - create API Gateway to read schools
    // For now, they are hard coded in constants folder
    // // load school names
    // guest_client.query({
    //   query: gql(getSchoolNames),
    // })
    // .then(({data}) => 
    //   localStorage.setItem("schoolNames", JSON.stringify(data.getSchoolNames.schoolNames))
    // )
    // .catch(err => console.log(err))

    this.setState({mounted: true})
  }

  // Inspectlet
  ldinsp = () => {
    if (typeof window.__inspld !== "undefined") return
    window.__inspld = 1
    const insp = document.createElement("script")
    insp.type = "text/javascript"
    insp.async = true
    insp.id = "inspsync"
    insp.src =
      ("https:" === document.location.protocol ? "https" : "http") +
      "://cdn.inspectlet.com/inspectlet.js?wid=1646906913&r=" +
      Math.floor(new Date().getTime() / 3600000)
    // insp.src =`${("https:" === document.location.protocol ? "https" : "http")}
    //   ://cdn.inspectlet.com/inspectlet.js?wid=1646906913&r=
    //   ${Math.floor(new Date().getTime() / 3600000)}`
    const x = document.getElementsByTagName("script")[0]
    x.parentNode.insertBefore(insp, x)
  }

  // Intercom
  intercom = () => {
    var w = window
    var ic = w.Intercom
    if (typeof ic === "function") {
        ic("reattach_activator")
        ic("update", w.intercomSettings)
    } else {
        var d = document
        var i = function() {
            i.c(arguments)
        }
        i.q = []
        i.c = function(args) {
            i.q.push(args)
        }
        w.Intercom = i
        var l = function() {
            var s = d.createElement("script")
            s.type = "text/javascript"
            s.async = true
            s.src = "https://widget.intercom.io/widget/vsqeg8oe"
            var x = d.getElementsByTagName("script")[0]
            x.parentNode.insertBefore(s, x)
        }
        if (w.attachEvent) {
            w.attachEvent("onload", l)
        } else {
            w.addEventListener("load", l, false)
        }
    }
  }

  render() {
    const { mounted } = this.state
    return !mounted ? <LoadingPage /> :(
      <ContextProvider>
        {/* <RoutingEngine /> */}
        <Switch>
          <Route path={routes.start()} exact component={views.HomePage} />
          <Route path={routes.login()} exact component={views.LoginPage} />
          <Route path={routes.signup()} exact component={views.SignUpPage} />
          <Route path={routes.signupWithToken()} exact component={views.SignUpPage} />
          <Route path={routes.loginWithToken()} exact component={views.SignUpPage} />
          <Route path={routes.setPassword()} component={views.LoginWithTokenPage} />
          <Route path={routes.backup_email()} exact component={views.BackupEmailPage} />
          <Route path={routes.error()} exact component={views.ErrorPage} />
          <Route path={routes.prequal()} exact component={views.PrequalPage} />
          <Route path={routes.ug_prequal()} exact component={views.PrequalPage} />
          <Route path={routes.refi_prequal()} exact component={views.PrequalPage} />
          <Route path={"/beta"} exact component={views.PrequalPage} />
          <Route path={routes.post_prequal()} component={views.PostPrequalPage} />
          <Route path={routes.post_preq_missing_status()} component={views.PostPrequalPage} />
          <Route path={routes.post_app()} component={views.PostAppPage} />
          <Route path={routes.career_work_shop_registration()} component={views.CareerWorkshopRegistrationPage} />
          <Route path={routes.unauth_portal()} component={views.UnAuthPortalPage} />
          <Route path={routes.post_signature()} component={views.PostSignaturePage} />
          <PrivateRoute path={routes.undergrad_app()} component={views.UndergradAppPage}/>
          <PrivateRoute path={routes.refi_app()} component={views.ReFiAppPage}/>
          <PrivateRoute path={routes.refi_payoff()} component={views.PayoffVerificationPage}/>
          {/* <PrivateRoute path={routes.refi_app_linkedin_redirect()} component={views.ReFiAppPage}/> */}
          <PrivateRoute path={routes.undergrad_reapply()} component={views.UndergradReApplyPage}/>  
          <PrivateRoute path={routes.portal()} component={views.PortalPage}/>  
          <PrivateRoute path={routes.magicRoute()} component={views.RoutingEngine}/>  
          <PrivateRoute path={routes.logoff()} component={views.LogoffPage}/>  
        </Switch>
      </ContextProvider>
    );
  }
}

export default App;