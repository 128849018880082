// import { awsHostedUILoginEndpoint } from 'common/cognito/endpoints.js';

// used by "GetCustomerNotifications" Lambda!! (aka do not change)

export default {
  start: () => '/',
  error: () => "/error-page",
  // undergrad_prequal: () => '/undergrad-prequal',
  prequal: () => '/prequalify', // this page should let users select a product
  ug_prequal: () => '/ug-eligiblity-survey',
  refi_prequal: () => '/refi-eligiblity-survey',
  // prequal: loanType => loanType ? `/prequal/${loanType}` : '/prequal/:loanType',
  post_prequal: status => status ? `/prequalSubmitted/${status}` : '/prequalSubmitted/:status',
  post_preq_missing_status: () => '/prequalSubmitted',
  undergrad_app: () => '/undergrad-application',
  refi_app: () => '/refinance-application',
  refi_payoff: () => '/refinance-payoff-verification',
  refi_app_linkedin_redirect: () => '/refinance-application/linkedin-redirect', // do not change. must be changed in LinkedIn API config too.
  undergrad_reapply: () => '/undergrad-reapply',
  loginWithToken: () => '/resume-application/:token',
  setPassword: token => token ? `/set-password/${token}` : '/set-password/:token',
  post_app: () => '/application-complete',
  magicRoute: () => '/routing',
  // login: () => `https://${awsHostedUILoginEndpoint}`
  login: () => '/login',
  signup: () => '/signup',
  logoff: () => '/logoff',
  signupWithToken: () => '/signup/:token',
  backup_email: () => '/backup-email',
  career_work_shop_registration: () => '/career-boot-camp-registration',
  post_signature: () => '/signature-complete', // Connected to "SendSignatureRequest" lambda 
  // resources
  unauth_portal: () => '/resources',
  // sections of the portal - must be authenticated
  portal: () => '/portal',
  portal_job_search: () => '/portal/job-search',
  // portal_career_center: () => '/portal/career-center',
  portal_financial_support: () => '/portal/financial-support',
  portal_mentor_network: () => '/portal/mentor-network',
  portal_resume_review: () => '/portal/resume-review',
}