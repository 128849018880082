import { GetAppSyncPublicAPIKey } from './endpoints'

const sessionStorageKey = 'GuestAPIKey'

async function fetchAPIKey() {
  let response = await fetch(GetAppSyncPublicAPIKey)
  let data = await response.json()
  sessionStorage.setItem(sessionStorageKey, data.api_key)

  // fetch(GetAppSyncPublicAPIKey)
  //   .then(response => response.json())
  //   .then(json => {
  //     sessionStorage.setItem(sessionStorageKey, json.api_key)
  //   })
  //   .catch(err => console.log(err))
}

function readAPIKey() {
  return sessionStorage.getItem(sessionStorageKey)
}

export default {
  fetchAPIKey,
  readAPIKey
}