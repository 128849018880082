import ReactGA from 'react-ga'

export const initGA = () => {
  ReactGA.initialize('UA-142956078-1')
  // ReactGA.initialize('UA-142956078-1', {
  //     debug: true,
  //     titleCase: false,
  // });
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}
