import React, {Component} from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  companyColor,
} from "assets/jss/material-kit-pro-react.jsx";

const styles = theme => ({
  container: {
    // margin: theme.spacing(6)
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  progress: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'stretch',
    flexDirection: 'column',
    color: companyColor[1] // "#14364C"
  },
});

class LoadingPage extends Component {
    static defaultProps = {
      size: 240,
      thickness: 2
    }
    render() {
      const { classes, size } = this.props;
      return(
        <div className={classes.container}>
          <CircularProgress 
            className={classes.progress} 
            size={size} 
            thickness={2}
            // color="primary" 
          />
        </div>
      );
    }
}

LoadingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoadingPage);