import cognito_details from './cognitoDetails'

export const awsCognitoEndpoint = 
`${cognito_details.aws_cognito_domain}.auth.us-east-1.amazoncognito.com`

export const awsHostedUILoginEndpoint = 
`${awsCognitoEndpoint}/login?response_type=${cognito_details.response_type}&client_id=${cognito_details.client_id}&redirect_uri=${cognito_details.login_redirect_uri}`

export const getJWTCognitoEndpoint = 
`${awsCognitoEndpoint}/oauth2/token`

export const cognitoIDP = `cognito-idp.us-east-1.amazonaws.com/${cognito_details.userPoolId}`