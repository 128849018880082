import ApolloClient from "apollo-boost";
import { InMemoryCache } from 'apollo-cache-inmemory';
import awsconfig from '../aws-exports';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import {amUserAPI, amGuestAPI} from './api/endpoints';
import { Auth } from 'aws-amplify';
import API_key from './api/appSyncAPIKey';

export const guest_client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: amGuestAPI,
    fetchOptions: {
      credentials: 'include'
    },
    request: async operation => {
      try {
        operation.setContext({
          headers: {
            'X-API-KEY': API_key.readAPIKey()
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
});

export const user_client = new AWSAppSyncClient({
  url: amUserAPI,
  region: awsconfig.aws_appsync_region || 'us-east-1',
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).idToken.jwtToken
  }
})