import {awsCognitoEndpoint} from './endpoints'
import cognito_details from './cognitoDetails'

export const oauth_config = {
    domain: awsCognitoEndpoint,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: cognito_details.login_redirect_uri,
    redirectSignOut: cognito_details.signout_redirect_uri,
    responseType: cognito_details.response_type
};

export const Auth_config = {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: cognito_details.identityPoolId,
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: 'us-east-1', 
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: cognito_details.userPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: cognito_details.client_id,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // this is the most fucking stupid thing in the world
    // why is cognito so complicated?????????????????

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: window.location.hostname, //'localhost',
    // // OPTIONAL - Cookie path
    //     path: '/',
    // // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    // // OPTIONAL - Cookie secure flag
    // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },


    // OPTIONAL - customized storage object
    // storage: new MyStorage(),
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH'
}