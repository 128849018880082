/* eslint no-nested-ternary: 0 */
// @flow
import React from 'react'
import { 
  Route, 
  // Redirect 
} from 'react-router-dom'
import omit from 'lodash/omit'
import routes from './routes.js'
import {Context} from 'components/ContextProvider/ContextProvider'
import LoadingPage from 'views/LoadingPage/LoadingPage'
// amplify auth libraries
import { withOAuth } from 'aws-amplify-react';

type PrivateRouteProps = {
  component: React.Component,
  location: Object,
  loggedOutComponent: React.Component
}

const PrivateRoute = ({
  component: Component,
  loggedOutComponent: LoggedOutComponent,
  ...rest
}: PrivateRouteProps) => (
  <Context.Consumer>
    {({ authState }) => (
      <Route
        {...rest}
        render={props =>
          (authState === 'signedIn') ? (
            <Component {...props} />
          ) : LoggedOutComponent ? (
            <LoggedOutComponent {...omit(props, ['loggedOutComponent'])} />
          ) : (authState === 'loading') ? (
            <LoadingPage />
          ) : (
            <Route component={() => { 
              window.location.href = routes.login(); 
              return null;
            }}/>
          )
        }
      />
    )}
  </Context.Consumer>
)

export default withOAuth(PrivateRoute)